.devbox {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 7px 0px #c5c5c5;
  margin: 20px 0;
}

.message--alert {
  color: #ff0000;
}

.emailSend--error {
  font-size: 0.8rem;
  line-height: 24px;
}

.emailSend--success {
  font-size: 0.8rem;
  line-height: 24px;
}

.generic-label {
  margin-top: 20px;
}

.label-for-radio {
  margin-top: 20px;
  margin-bottom: 0px;
}

.radioGroup__generic .radioButton {
  display: inline-block;
}

.radioButton input[type="radio"] {
  display: none;
}

.radioButton label {
  position: relative;
  display: inline-block;
  padding: 4px 4px 4px 20px;
  cursor: pointer;
}

.radioButton label::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  background: var(--color-secondary);
  border: 1px solid var(--color-primary);
  border-radius: 100%;
}

.radioButton input[type="radio"]:checked+label::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 4px;
  width: 8px;
  height: 8px;
  margin-top: -4px;
  background: var(--color-primary);
  border-radius: 100%;
}

.checkboxButton input[type="checkbox"] {
  display: none;
}

.checkboxButton label {
  position: relative;
  display: inline-block;
  padding: 4px 4px 4px 24px;
  cursor: pointer;
  line-height: initial;
}

.checkboxButton label::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  background: var(--color-secondary);
  border: 1px solid var(--color-primary);
  border-radius: 10%;
}

.checkboxButton input[type="checkbox"]:checked+label::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 4px;
  width: 8px;
  height: 8px;
  margin-top: -4px;
  background: var(--color-primary);
  border-radius: 10%;
}

.radioGroup__generic label {
  margin-right: 10px;
}

.radioGroup__generic input {
  margin-right: 5px;
}

.ecommerce_alert {
  position: fixed;
  top: 0;
  bottom: 20%;
  left: 0;
  right: 0;
  z-index: 9999;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: var(--color-primary);
  font-size: 16px;
}

.ecommerce_alert .inner {
  max-width: 640px;
  padding: 30px 60px;
  background-color: var(--color-porcelain);
  border: 1px solid var(--color-primary);
  text-align: center;
}

.ecommerce_alert .buttons {
  width: 100%;
  font-size: 20px;
  margin-top: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.ecommerce_alert button {
  border: 0;
  background: var(--color-primary);
  color: var(--color-secondary);
  transition: 0.5s;
  min-width: 100px;
  text-align: center;
  font-size: 16px;
}

.ecommerce_alert button:nth-child(2n) {
  margin-left: 1em;
}

.ecommerce_alert button:active,
.ecommerce_alert button:focus {
  outline: 0;
}

.title-devivery-mode {
  text-transform: uppercase;
  text-decoration: none !important;
  font-size: 16px !important;
}

.customerCareInfo {
  margin-top: 60px;
  margin-bottom: 60px;
}

.focus {
  color: #640000;
  font-weight: bold;
}

.checkout-column-main li.radioButton label {
  cursor: pointer;
}

.checkout-column-main li.radioButton label:hover {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .checkout-active-step {
    border: 2px solid #968870 !important;
  }

  .checkout-active-step h3 {
    color: #968870;
  }
}

.focus-info{
  line-height: normal;
}